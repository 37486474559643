import '../../homePageStyle.css'
import useCollapse from 'react-collapsed';
import { Container,OverlayTrigger,Tooltip } from 'react-bootstrap';
import { useHistory, useLocation,useParams } from "react-router-dom";
import { useEffect, useState,React,Fragment, useRef } from "react";
import {ModuleZero} from "./ModuleZero";
import axios from "../../axios-interceptor";
import moment from "moment"
import {random_number, base_url,date_formats,downloadPdf,api_error_message, user_maintenance_type, badgeMessage, moduleBadgeIssuing} from '../../utilities';
import ReactTooltip from "react-tooltip";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import badgeImgData from '../../BadgeImage'
import withMaintenanceCheck from '../../HigherOrderComponents/maintenancePageChecker';
const ModulePage = () => {
  let [past_courses,setPastCourse] = useState([])
  let [course_complete,setCourseComplete] =useState(false)
  let [certificate_issued,setcertificate_issued] =useState(false)
  let [courseTakeAway,setCourseTakeAway] =useState("")
  const history = useHistory();


  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();
  // console.log(JSON.parse(localStorage.getItem('audit_user')))
    const location = useLocation();
    const params  = useParams();
    var moduleData;
    var [course_id,setCourseId]=useState('')
    var redirect_course_id
    let [timer_flag, setTimeFlag] = useState(true);
  const [course_title,setCourseTitle] = useState('');
  const [modules, setModules] = useState([]);
  const [content, setContent] = useState([]);
  let [dynamic_badge, SetDynamicBadge] = useState({});
 
   useEffect(()=>{
    if(!localStorage.getItem('User_Accesstoken')){
      history.push('/');
    }
    else{
      localStorage.removeItem('artcle_LinkClicked')
      localStorage.removeItem('confirmation_code')
      if(params?.id !== undefined){
        axios
        .post(base_url + "courses/get_all_courses",)
        .then((res) => {
        res.data.courses.map((item,index)=>{
         if(params.id == index+1){
          redirect_course_id = item.id
          
         }
        })
        setCourseId(redirect_course_id)
        localStorage.setItem('redirect_course_id',redirect_course_id)
        axios.post(base_url+"courses/get_course_details", {
          "course_id":redirect_course_id
        })
        
        .then((res)=>{
          setCourseTitle(res.data.result.course.title)
          setTimeFlag(false);  
         
         
        }).catch((err)=>{
          console.log(err);
          if(err.response?.statusText=='Unauthorized'||err.response?.data?.statusText=='Unauthorized'){
            localStorage.clear();
            history.push('/')
            window.location.reload();
          }else{
            toast.warn(api_error_message,{toastId:random_number});
          }
          
        })
        })
        .catch((err) => {
          console.log(err);
          if(err.response?.statusText=='Unauthorized'||err.response?.data?.statusText=='Unauthorized'){
            localStorage.clear();
            history.push('/')
            window.location.reload();
          }else{
            toast.warn(api_error_message,{toastId:random_number});
          }
        });
      }else{
        if(localStorage.getItem('complete_lesson')){
          axios
          .post(base_url + "lesson/progress_tracking", {
            user_id: localStorage.getItem("user_id"),
            lesson_id: localStorage.getItem("lessonId"),
            course_id: localStorage.getItem("course_id"),
            module_id: localStorage.getItem("moduleId"),
            cohort_schedule_id: localStorage.getItem("cohort_id"),
          })
          .then((res) => {      
          localStorage.setItem("lesson_tracking", res.data);
          if(res.data.is_module_completed){
            axios
            .post(base_url + "module/progress_tracking", {
              user_id: localStorage.getItem("user_id"),
  
              course_id: localStorage.getItem("course_id"),
              module_id: localStorage.getItem("moduleId"),
              cohort_schedule_id: localStorage.getItem("cohort_id"),
            })
            .then((res) => {
              get_cohort_details();
            })
            .catch((err) => {
              console.log(err);
              if(err.response?.statusText=='Unauthorized'||err.response?.data?.statusText=='Unauthorized'){
                localStorage.clear();
                history.push('/')
                window.location.reload();
              }else{
                toast.warn(api_error_message,{toastId:random_number});
              }
            });
            }else{
              get_cohort_details();
            }
          })
          .catch((err) => {
            console.log(err);
            if(err.response?.statusText=='Unauthorized'||err.response?.data?.statusText=='Unauthorized'){
              localStorage.clear();
              history.push('/')
              window.location.reload();
            }else{
              toast.warn(api_error_message,{toastId:random_number});
            }
          });
        }else{
          get_cohort_details();
        }
       
      }
    }
   },[])
   const ShowDynamicBadge =  (id) => { 
    SetDynamicBadge({})
    SetDynamicBadge(prevFlags => ({ ...prevFlags, [id]: true }));
  }

  const HideDynamicBadge =  (id) => {
    SetDynamicBadge(prevFlags => ({ ...prevFlags, [id]: false }));
  }

  const goToScorePage = () =>{
    history.push('/scorecard')
  }

  const CourseCompletionConfirmation = async () => {
    try {
     const res1 = await axios.post(base_url + "notifications/course_completion_confirmation", {
    user_id: localStorage.getItem("user_id"),
    cohort_schedule_id: localStorage.getItem("cohort_id"),
  });
  if(res1){
    PastCourses()
  }
  else{
    PastCourses()
  }
}catch (error) {
  PastCourses()
}
  }
  const PastCourses = ()=>{
    axios.post(base_url+'courses/get_past_courses',{
      user_id: localStorage.getItem("user_id"),
      cohort_schedule_id: localStorage.getItem("cohort_id")
  }).then(res=>{
    if(res.data.data.length >0){
      setCourseComplete(true)
      setCourseTakeAway(res.data?.data[0].course_takeaway)
      if(res.data?.data[0].course_is_completed ==1){
        setcertificate_issued(true)
      }
      else{
        setcertificate_issued(false)
      }
    }
     
     setPastCourse(res.data.data);

     setTimeFlag(false);
    
  }).catch(err=>{
      console.log(err);
      setTimeFlag(false);
      if(err.response?.statusText=='Unauthorized'||err.response?.data?.statusText=='Unauthorized'){
        localStorage.clear();
        history.push('/')
        window.location.reload();
      }else{
        toast.warn(api_error_message,{toastId:random_number});
      }
      
  })
  }
  async function get_cohort_details() {
    try {
      setTimeFlag(true);
      const res = await axios.post(base_url + "courses/get_course_schedules_details", {
        user_id: localStorage.getItem("user_id"),
        course_id: localStorage.getItem('course_id'),
        cohort_schedule_id: localStorage.getItem("cohort_id"),
      });
  
      setCourseId(localStorage.getItem('course_id'));
      let modules = res.data.result.course.modules[0];
      localStorage.setItem("programe_id",res.data.result.course.program_id)
      let completed = [];
      modules.map(item => {
        if (item.module_completed_percentage === "100.00") {
          completed.push(item.module_completed_percentage);
        }
      });
      try{
        await CourseCompletionConfirmation();
      }catch(err){
        CourseCompletionConfirmation();
        if (err.response?.statusText == 'Unauthorized'||err.response?.data?.statusText == 'Unauthorized') {
          localStorage.clear();
          history.push('/');
          window.location.reload();
        }
      } 
      if (JSON.parse(localStorage.getItem('audit_user')) == true) {
        let res_length = res.data.result.course.modules[0].length;
        let modules = res.data.result.course.modules[0];
        let id = modules[res_length - 1].lessons[0][0].module_id;
        modules[res_length - 1].lessons[0].push({
          title: "Feedback",
          module_id: id,
          completed_percentage: "0.00",
          is_lesson_completed: false,
          total_score: 0,
          earned_score: 0,
          lesson_id: 1
        });
        setModules(modules);
      } else {
        setModules(res.data.result.course.modules[0]);
      }
      setCourseTitle(res.data.result.course.title);
      localStorage.removeItem('complete_lesson');
    } catch (err) {
      if (err.response?.statusText == 'Unauthorized'||err.response?.data?.statusText == 'Unauthorized') {
        localStorage.clear();
        history.push('/');
        window.location.reload();
      } else {
        toast.warn(api_error_message, { toastId: random_number });
      }
    }
  }
  const shareCourseCertificate=()=>{
    axios.post(base_url+'get_course_certificate_shareable_meta_data',{
      user_id: localStorage.getItem("user_id"),
      course_id: localStorage.getItem('course_id'),
      cohort_schedule_id: localStorage.getItem("cohort_id")
    })
    .then(res=>{
      const certificate_id=res.data?.badge_url
      if(certificate_id)
      {
        window.location.assign(`/backend/credentials/${certificate_id}`)
      }
      else{
        toast.warn("Failed to share", { toastId: random_number });
      }
    })
    .catch(err=>{
      console.log(err);
    })
     }


     //badge sharing 
const canvasRef = useRef(null);
const imgRef = useRef(null);

useEffect(() => {
  imgRef.current = new Image();
  imgRef.current.src = badgeImgData
  imgRef.current.onload = () => {
  //  const noValue=""
  // generatePreview("Decision Making","CRITICAL THINKING");
  };
}, []);

useEffect(()=>{
  getAws();
},[]);

const getAws=async()=>{
await axios.get(base_url+"isbx/get_aws_video_links")
.then((res)=>{
  sessionStorage.setItem("s3AwsVideoLinks",JSON.stringify(res.data?.data))
})
.catch((err)=>{
  console.log("error",err)
})
}

const generatePreview = (ModuleNameInput,CourseNameInput) => {
  const canvas = canvasRef.current;
  const context = canvas.getContext("2d");

  const textInput = ModuleNameInput
  const courseText=CourseNameInput

  canvas.width = imgRef.current.width;
  canvas.height = imgRef.current.height;

  context.clearRect(0, 0, canvas.width, canvas.height);
  context.drawImage(imgRef.current, 0, 0);

  context.font = "36px Arial";
  context.fillStyle = "white";
  context.textAlign = "center";
  context.textBaseline = "middle";

  const maxWidth = 350; // Maximum width of the text
  const lineHeight = 42; // Line height for the text
  const x = canvas.width / 2; // Horizontal center position
  let y = ModuleNameInput.length >= 33?215:canvas.height / 2 - lineHeight; // Vertical center position

  // Split text into lines based on the maximum width
  const words = textInput.split(' ');
  const lines = [];
  let currentLine = words[0];
  for (let i = 1; i < words.length; i++) {
    const word = words[i];
    const width = context.measureText(currentLine + ' ' + word).width;
    if (width < maxWidth) {
      currentLine += ' ' + word;
    } else {
        lines.push(currentLine);
        currentLine = word;
    }
  }
    lines.push(currentLine);
  // Draw each line of text
  for (let j = 0; j < lines.length; j++) {
    context.fillText(lines[j], x, y + j * lineHeight);
  }

  if(ModuleNameInput!=""){
    generateBadge()
  }
  };

const generateBadge = () => {
  const canvas = canvasRef.current;
  canvas.toBlob((blob) => {
    if (blob) {
      const fd = new FormData();
      let user_id= localStorage.getItem("user_id")
      let course_id= selectedModuleInfo.course_id
      let module_id= selectedModuleInfo.module_id
      let cohort_schedule_id= selectedModuleInfo.cohort_schedule_id
       if(user_id&&course_id&&module_id&&cohort_schedule_id)
       {
         fd.append("user_id",user_id)
         fd.append("course_id",course_id)
         fd.append("module_id",module_id)
         fd.append("cohort_schedule_id",cohort_schedule_id)
         fd.append("file",blob,`${selectedModuleInfo.module_name}-${selectedModuleInfo.course_name}.jpg`)  
         axios.post(base_url+'badges/generate_badge_url',fd)
         .then(res=>{
           const certificate_id=res.data?.badge_url
           if(certificate_id)
           {
            window.location.assign(`/backend/credentials/${certificate_id}`)
           }
           else{
           //  setLoadingFlag(false)
             toast.warn("Failed to share", { toastId: random_number });
           }
         })
         .catch(err=>{
         //  setLoadingFlag(false)
           console.log(err);
         })
       }
       else{
        // setLoadingFlag(false)
         toast.warning("Something went wrong")
       }
    }
  }, "image/jpeg");
};

let [selectedModuleName,setSelectedModuleName]=useState('')
let [selectedCourseName,setSelectedCourseName]=useState('')
let [selectedModuleInfo,setSelectedModuleInfo]=useState({})
const shareHandler=(module,item)=>{
   let stateObject={
     cohort_schedule_id:item.cohort_schedule_id,
     course_id:item.course_id,
     course_name:item.course_title,
     module_id:module._id,
     module_name:module.module_title
   }
      setSelectedModuleInfo(stateObject)
      setSelectedModuleName(module.module_title)
      setSelectedCourseName(item.course_title)
   }

useEffect(() => {
 let modulename=selectedModuleName.toUpperCase()
 let courseName=selectedCourseName.toUpperCase()
 if(modulename&&courseName)
 {
   generatePreview(modulename,courseName)
 }
}, [selectedModuleName,selectedCourseName])
  
    return (
<>
  {/* badge sharing */}
  <canvas  className="hidden_badge_sharing" ref={canvasRef}></canvas>
    <div className="hidden_badge_sharing" id="previewContainer"></div>
{modules.length != 0  && (
  <Container className='bg-transparent outlinemobile mt-5 pb-0'>
    <div className="d-flex justify-content-between w-100">
      <h1 data-testid="Course_title" className={`${window.innerWidth>993?'':'mt-4'} outline_mainheading blue_text m-0 align-self-start`}>{course_title}</h1>
      {past_courses[0]?.show_overview && <div className="align-self-end">
        <button type="button" data-testid="Progress_card" className="btn vie_othr_course_btn rounded-3 w-100" onClick={goToScorePage}>Progress Card</button>
      </div>}
    </div>
  </Container>
)}



<Container className='bg-transparent outlinemobile'>
  <div className='d-none' data-testid="dummyfunctionCall" onClick={generateBadge}></div>
{timer_flag ? (
        <div>
        <img data-testid="atrium_loader" className="atrium_loader" src="images/atrium_loader.gif" alt="Loader" />
      </div>
            ):
         
            <>
              {
window.innerWidth>993?
                (course_complete ) &&
            past_courses.map((item,index)=>{
                return(
                    <Fragment key={index}>
                    {past_courses[0]?.show_overview && <div className="p-4 bg-body container shadow-sm mt-3" key={index}>

            <div className="row mt-2">
            <div className="col-9 d-block">
              {/* <span className='course_complete_span'>
                {item.course_is_completed === 1 ? (
                  `You have Completed this Course on ${date_formats.human_date_format(item.course_completion_date)}`
                ) : (
                  `Course completed on ${date_formats.human_date_format(item.cohort_end_date)}`
                )}
              </span> */}
            </div>
            <div className="col-3">
            {courseTakeAway && (courseTakeAway !='null' ) ?  (<div className='' data-testid="course_takeaway_web"><a className={`btn  submit_button fw-bold my_certificate_btn m-0  my_btn_cls top-0 end-0 my_btn_cl2 lesson_page_outline px-4 ${date_formats.convert_current_date_utc_format() > item.cohort_end_date?'':'disabled'}`}  href={courseTakeAway}  target="_blank" download>Download Takeaway</a></div>):''}

            </div>
                
              <div>
              {/* <span>Completed <b>{moment(item.course_completion_date).format('D MMM, YYYY')}</b></span> &nbsp; */}
              {/* <>
                { (item.certificate_url) ? 
                  <button className="btn my_btn_cls submit_button fw-bold" onClick={() => downloadPdf(item.certificate_url)} download>Download Takeaway</button>
                :
                  <a className="btn my_btn_cls submit_button fw-bold" href="/images/course_certificate.svg" download>Download Takeaway</a>
                }
              </> */}
              {/* <button className="btn my_btn_cls submit_button m-0" ></button> */}
              </div>
              </div>
              <div className="row mt-4">
                <div className="col-lg-3">
                  <p className="blue_text badges-class" data-testid="your_scoreText">Your Score</p>
                  <p data-testid="your_scoreValue"><b>{item.total_earned_score} </b>/ {item.total_course_score}  Points</p>
                  <p className="blue_text badges-class mt-4"  data-testid="time_spentText">Time Spent</p>
                  <p data-testid="time_spentValue"><b>{Math.floor(moment.duration(item.total_time_spent,'seconds').asHours())!=0? Math.floor(moment.duration(item.total_time_spent,'seconds').asHours()) + ' hrs ' + moment.duration(item.total_time_spent,'seconds').minutes() + ' min ' :moment.duration(item.total_time_spent,'seconds').minutes() + ' min ' }</b></p>
                </div>
                <div className="col-lg-6">
                  <div className="row">
                    <p className="blue_text badges-class">Badges</p>
                    {item.modules.map((module,moduleINdex)=>{
                     
                      return (
                        <Fragment key={moduleINdex}>
                          {module.module_badge === 0 && (
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip className="module_tooltip" id={"registerTip0" + module._id + module.start_date}>
                                   {moduleBadgeIssuing(module.user_earned_module_score,module.module_total_score,module.module_score_percentage,module.badge_percentage,module.points_needed_for_badge,module.end_date)}
                                </Tooltip>
                              }
                              offset={[0, 20]}
                            >
                              <div
                                onMouseEnter={() => ShowDynamicBadge("registerTip0" + module._id + module.start_date)}
                                className="transparent_isb_badge" data-testid="transparent_badge_web"
                                id={"registerTip0" + module._id + module.start_date}
                                onMouseLeave={() => HideDynamicBadge("registerTip0" + module._id + module.start_date)}
                              >
                                <div className="isb_badge_text">
                                  <p className="text-center mb-2 white_color">
                                    {module?.module_title?.length >= 43 ? module?.module_title.slice(0, 43) + '...' : module?.module_title}
                                  </p>
                                </div>
                              </div>
                            </OverlayTrigger>
                          )}
                      
                          {module.module_badge !== 0 && (
                            <OverlayTrigger
                              placement="top"
                              overlay={
                                <Tooltip className="module_tooltip" id={"registerTip2" + module._id + module.start_date}>
                                  <span>
                                  {badgeMessage}
                                  </span>
                                </Tooltip>
                              }
                              offset={[0, 20]}
                            >
                              <div onClick={() => { if (module.badge_sharing === 0) {shareHandler(module, item)}}} onMouseEnter={() => ShowDynamicBadge("registerTip2" + module._id + module.start_date)} data-testid="isb_badge_web" className="isb_badge" id={"registerTip2" + module._id + module.start_date} onMouseLeave={() => HideDynamicBadge("registerTip2" + module._id + module.start_date)}>
                                <div className="isb_badge_text">
                                  <p className="text-center mb-2 white_color">
                                    {module?.module_title?.length >= 43 ? module?.module_title.slice(0, 43) + '...' : module?.module_title}
                                  </p>
                                </div>
                              </div>
                            </OverlayTrigger>
                          )}
                        </Fragment>
                      );
                      
                      
                    })}
                  </div>
                </div>
                <div className="col-lg-3">
                {/* <button className={`disabled btn my_outline_button_cls submit_button fw-bold my_certificate_btn m-0 px-4`} onClick={() => downloadPdf(item.certificate_url)} download>Download Certificate</button> */}

                {item.certificate_url ? <p data-testid="certificate_heading_web" className="blue_text text-left certificate-class mb-0">Certificate</p>:<></>}
                  {item.certificate_url ?  <img data-testid="certificate_thumbnail_web" className={`course_complete_margin_forimage  ${item.certificate_thumbnail_url?"":"opacity-25"}`} src={item.certificate_thumbnail_url}></img>:
                  (item.course_score_percentage >= item.certificate_pass_percentage? 
                  <>
                   <img className={`course_complete_margin_forimage  ${item.course_score_percentage < item.certificate_pass_percentage?"":"opacity-25"}`} src="/images/course_certificate.svg"></img>
                { item.course_custom_feedback==1?
                <span>Congratulations! Your <br /> certificate  will be released <br /> on {date_formats.human_date_format(item.program_hard_deadline)}.</span>
                :  <span className='ms-0'> Congratulations! Just one step left for your certificate.<br/> Share feedback to access it.</span>}
                  </>: 
                  <div className='module_certificate_class' data-testid="courseCompletetionInfo_web">
                    {/* // item.certificate_pass_percentage is for dynamic percentage */}
                    <span data-testid="certificateNotissued_web">
                      This course will end on {date_formats.convert_current_date_utc_format() < item.cohort_end_date ? date_formats.human_date_format(item.cohort_end_date): date_formats.human_date_format(item.cohort_expiry_date)}. Your certificate will be issued after achieving {Math.round(item.certificate_pass_percentage)?Math.round(item.certificate_pass_percentage)
                      :<img src="/images/loading_dots.gif" className="dots_loader" alt="Loader" ></img>}% by {date_formats.convert_current_date_utc_format() < item.cohort_end_date ? date_formats.human_date_format(item.cohort_end_date): date_formats.human_date_format(item.cohort_expiry_date)}.</span>
                    
                    <br/>
                    <br/>
                    <br/>
                    <span>You only need to complete {Math.round(item.score_percentage_required_for_certificate)}% more to be awarded your certificate.</span>
                    </div>)
                    }
                  <div className='d-flex gap-3 mt-3'>
                          <>
                            { (item.certificate_url) ? 
                            <>
                            <button data-testid="certificate_DownloadBtn_web" className="btn my_outline_button_cls submit_button fw-bold my_certificate_btn m-0 px-4" onClick={() => downloadPdf(item.certificate_url)} download>Download</button>
                            <button data-testid="certificate_ShareBtn_web" className="btn my_btn_cls mt-0 my_share_btn" onClick={shareCourseCertificate} >Share</button>
                            </>
                            :''
                            // <a className="btn my_outline_button_cls submit_button fw-bold my_certificate_btn m-0" href="/images/course_certificate.svg"  download>Download Certificate</a>
                            // <span>Due to your low score, the final course  certificate cannot be issued</span>
                            }
                          </>
                          </div>
                </div>
              </div>
            
            </div>}
                    </Fragment>
                )
            }): (course_complete ) &&
            past_courses.map((item,index)=>{
                return(
                    <Fragment key={index}>
                     {past_courses[0]?.show_overview && 
                     <div className="p-4 bg-body  shadow-sm mt-3" >

            <div className="row mt-2">
            <div className="col-9">
              {/* <span className='course_complete_span'>
                {item.course_is_completed === 1 ? (
                  `You have Completed this Course on ${date_formats.human_date_format(item.course_completion_date)}`
                ) : (
                  `Course completed on ${date_formats.human_date_format(item.cohort_end_date)}`
                )}
              </span> */}
            </div>
            <div className="text-end">
            {courseTakeAway && (courseTakeAway !='null' ) ?  (<div data-testid="course_takeaway_mobile"><a className={`btn float-end submit_button fw-bold my_certificate_btn m-0  my_btn_cls top-0 end-0 my_btn_cl2 lesson_page_outline ${date_formats.convert_current_date_utc_format() > item.cohort_end_date?'':'disabled'}`} href={courseTakeAway} target="_blank" download>Download Takeaway</a></div>):''}

            </div>
                
              <div>
              {/* <span>Completed <b>{moment(item.course_completion_date).format('D MMM, YYYY')}</b></span> &nbsp; */}
              {/* <>
                { (item.certificate_url) ? 
                  <button className="btn my_btn_cls submit_button fw-bold" onClick={() => downloadPdf(item.certificate_url)} download>Download Takeaway</button>
                :
                  <a className="btn my_btn_cls submit_button fw-bold" href="/images/course_certificate.svg" download>Download Takeaway</a>
                }
              </> */}
              {/* <button className="btn my_btn_cls submit_button m-0" ></button> */}
              </div>
              </div>
              
              <div className="row mt-4">
                <div className="col-6">
                  <p className="blue_text badges-class">Your Score</p>
                  <p><b>{item.total_earned_score} </b>/ {item.total_course_score}  Points</p>
                  <p className="blue_text badges-class mt-4">Time Spent</p>
                  <p><b>{Math.floor(moment.duration(item.total_time_spent,'seconds').asHours())!=0? Math.floor(moment.duration(item.total_time_spent,'seconds').asHours()) + ' hrs ' + moment.duration(item.total_time_spent,'seconds').minutes() + ' min ' :moment.duration(item.total_time_spent,'seconds').minutes() + ' min ' }</b></p>
                  <div className='col-12 mt-4'>
                  <div className="row mobile-module-row">
                    <p className="blue_text badges-class ml-0">Badges</p>
                    {item.modules.map((module,moduleIndex)=>{
                      return(
                          <Fragment key={moduleIndex}>
                          { ( module.module_badge == 0 ) ? 

                            <> { module.is_module_completed == true ? dynamic_badge["registerTip0" + module._id +module.start_date] ? <OverlayTrigger placement="top" overlay={
                              <Tooltip className="module_tooltip"
                              id={"registerTip0" + module._id +module.start_date}>
                                {moduleBadgeIssuing(module.user_earned_module_score,module.module_total_score,module.module_score_percentage,module.badge_percentage,module.points_needed_for_badge,module.end_date)}
                              </Tooltip>} offset={[0,20]}>
                              <div  onMouseEnter={() => ShowDynamicBadge("registerTip0" + module._id +module.start_date)} className="transparent_isb_badge mobile-badge" data-testid="transparent_badge_mobile" id={"registerTip0" + module._id +module.start_date} onMouseLeave={() => HideDynamicBadge("registerTip0" + module._id +module.start_date)} >
                                <div className="isb_badge_text">
                                  <p className="text-center mb-2 white_color">{module?.module_title?.length >= 43 ?  module?.module_title.slice(0, 43)+ '...' : module?.module_title }</p>
                                  {/* <p className="text-center white_color">{item?.course_title?.length >= 43 ? item?.course_title.slice(0, 43) + '...' : item?.course_title }</p> */}
                                </div>
                              </div>
                            </OverlayTrigger> :  
                            <div  onMouseEnter={() => ShowDynamicBadge("registerTip0" + module._id +module.start_date)} className="transparent_isb_badge mobile-badge" data-testid="transparent_badge_mobile"  id={"registerTip0" + module._id +module.start_date}>
                              <div className="isb_badge_text">
                                <p className="text-center mb-2 white_color">{module?.module_title}</p>
                                {/* <p className="text-center white_color">{item.course_title}</p> */}
                              </div>
                            </div>
                          : dynamic_badge["registerTip0" + module._id +module.start_date] ? <OverlayTrigger placement="top" overlay={
                            <Tooltip className="module_tooltip"
                            id={"registerTip0" + module._id +module.start_date}>
                               {moduleBadgeIssuing(module.user_earned_module_score,module.module_total_score,module.module_score_percentage,module.badge_percentage,module.points_needed_for_badge,module.end_date)}
                            </Tooltip>} offset={[0,20]}>
                            <div  onMouseEnter={() => ShowDynamicBadge("registerTip0" + module._id +module.start_date)} className="transparent_isb_badge mobile-badge" data-testid="transparent_badge_mobile"  id={"registerTip0" + module._id +module.start_date} onMouseLeave={() => HideDynamicBadge("registerTip0" + module._id +module.start_date)} >
                              <div className="isb_badge_text">
                                <p className="text-center mb-2 white_color">{module?.module_title?.length >= 43 ?  module?.module_title.slice(0, 43)+ '...' : module?.module_title }</p>
                                {/* <p className="text-center white_color">{item?.course_title?.length >= 43 ? item?.course_title.slice(0, 43) + '...' : item?.course_title }</p> */}
                              </div>
                            </div>
                          </OverlayTrigger> :  
                          <div  onMouseEnter={() => ShowDynamicBadge("registerTip0" + module._id +module.start_date)} className="transparent_isb_badge mobile-badge" data-testid="transparent_badge_mobile"  id={"registerTip0" + module._id +module.start_date}>
                            <div className="isb_badge_text">
                              <p className="text-center mb-2 white_color">{module?.module_title}</p>
                              {/* <p className="text-center white_color">{item.course_title}</p> */}
                            </div>
                          </div>}</>
                          :
                          <>{
                            module.is_module_completed == false &&
                          dynamic_badge["registerTip2" + module._id +module.start_date] ? <OverlayTrigger placement="top" overlay={
                            <Tooltip className="module_tooltip"
                            id={"registerTip2" + module._id +module.start_date}>
                              {badgeMessage}
                            </Tooltip>} offset={[0,20]}>
                            <div onClick={()=>shareHandler(module,item)}  onMouseEnter={() => ShowDynamicBadge("registerTip2" + module._id +module.start_date)} className="isb_badge mobile-badge"  id={"registerTip2" + module._id +module.start_date} onMouseLeave={() => HideDynamicBadge("registerTip2" + module._id +module.start_date)} >
                              <div className="isb_badge_text">
                                <p className="text-center mb-2 white_color">{module?.module_title?.length >= 43 ?  module?.module_title.slice(0, 43)+ '...' : module?.module_title }</p>
                                {/* <p className="text-center white_color">{item?.course_title?.length >= 43 ? item?.course_title.slice(0, 43) + '...' : item?.course_title }</p> */}
                              </div>
                            </div>
                          </OverlayTrigger> :  
                          <div onClick={()=>shareHandler(module,item)}  onMouseEnter={() => ShowDynamicBadge("registerTip2" + module._id +module.start_date)} className="isb_badge mobile-badge"  id={"registerTip2" + module._id +module.start_date}>
                            <div className="isb_badge_text">
                              <p className="text-center mb-2 white_color">{module?.module_title}</p>
                              {/* <p className="text-center white_color">{item?.course_title}</p> */}
                            </div>
                          </div>
                          }
                        </>
                          }
                          </Fragment>
                      )
                    })}
                  </div>
                  </div>
                </div>
                {/* <div className="col-lg-6"> */}
                  
                {/* </div> */}
                <div className="col-6">
                {/* <button className={`disabled btn my_outline_button_cls submit_button fw-bold my_certificate_btn m-0 px-4`} onClick={() => downloadPdf(item.certificate_url)} download>Download Certificate</button> */}

                {item.certificate_url ? <p className="blue_text text-left certificate-class mb-0 pl-4">Certificate</p>:<></>}
                  {item.certificate_url ?  <img className={`course_complete_margin_forimage w-100  ${item.certificate_url?"":"opacity-25"}`} src="/images/course_certificate.svg"></img>:
                  (item.course_score_percentage >= item.certificate_pass_percentage? 
                    <>
                     <img className={`course_complete_margin_forimage w-100 ${item.course_score_percentage < item.certificate_pass_percentage?"":"opacity-25"}`} src="/images/course_certificate.svg"></img>
                     { item.course_custom_feedback==1?<span>Congratulations! Your certificate will be released on Programme HardDeadline.</span>:  <span className='ms-0'>Congratulations! Just one step left for your certificate.<br/> Share feedback to access it.</span>}
                    </>:
                  <div className='module_certificate_class'>
                    <span>This course will end on {item.cohort_expiry_date?date_formats.human_date_format(item.cohort_expiry_date):date_formats.human_date_time_format(item.cohort_end_date)}. Your certificate will be issued after achieving {Math.round(item.certificate_pass_percentage)?Math.round(item.certificate_pass_percentage):<img src="/images/loading_dots.gif" className="dots_loader" alt="Loader" ></img>}% by {item.cohort_expiry_date?date_formats.human_date_format(item.cohort_expiry_date):date_formats.human_date_time_format(item.cohort_end_date)}.</span>
                    <br/>
                    <br/>
                    <span>You only need to complete {Math.round(item.score_percentage_required_for_certificate)}% more to be awarded your certificate.</span>
                    </div>)}
                 
                </div>
                <div className='d-flex justify-content-center gap-3'>
                          <>
                            { (item.certificate_url) ? 
                            <>
                            <button className="btn my_outline_button_cls submit_button fw-bold my_certificate_btn m-0 px-4" onClick={() => downloadPdf(item.certificate_url)} download>Download</button>
                            <button className="btn my_btn_cls mt-0 my_share_btn" onClick={shareCourseCertificate} >Share</button>
                            </>
                            :''
                            // <a className="btn my_outline_button_cls submit_button fw-bold my_certificate_btn m-0" href="/images/course_certificate.svg"  download>Download Certificate</a>
                            // <span>Due to your low score, the final course  certificate cannot be issued</span>
                            }
                          </>
                          </div>
              </div>
              
            </div>}
                    </Fragment>
                )
            })
         }
          
  <div >
  <div>
  {modules.map((item,index) => (
    <Fragment key={item.module_id}>
    <div >
      <ModuleZero data={item} id={item.module_id} course={course_id} index={index}  cohort_id={localStorage.getItem("cohort_id")} new_module_id={modules[0]['module_id']} modules={modules} new_module_end_date ={modules[0]['end_date']}  /> 
    </div>
    </Fragment>
  ))}
</div>
 
       
       
  </div>
  </>
}
</Container>
{/* </>
} */}
</>
  
    );
};

export default withMaintenanceCheck(ModulePage,user_maintenance_type) 
